import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import { Container, Row, Col } from "../components/UI/wrapper"
import Pagination from "../components/pagination"
import { ListadoWrapper, ListaDeProductosWrapper } from "./list-style"
import SEO from "../components/seo"
import Sidebar from "../containers/productos/sidebar"
import BoxLargeImage from "../components/box-large-image/layout-two"

const listaDeProductos = ({
  data,
  pageContext,
  location,
  linkStyle,
  boxStyles,
  sectionStyle,
  ...restProps
}) => {
  const products = data.allPrismicProducto.edges
  const { currentPage, numberOfPages } = pageContext

  return (
    <>
      <SEO title="Productos Seggasa" />
      <main className="site-wrapper-reveal">
        <ListaDeProductosWrapper>
          <Container>
            <Row>
              <Col lg={{ span: 4, order: 1 }} xs={{ span: 12, order: 2 }}>
                <Sidebar />
              </Col>
              <Col lg={{ span: 8, order: 2 }} xs={{ span: 12, order: 1 }}>
                <ListadoWrapper>
                  <Container>
                    <Row>
                      {products.map((item, index) => (
                        <Col
                          lg={4}
                          md={6}
                          className="box-item"
                          key={`box-image-${index}`}
                        >
                          <BoxLargeImage
                            {...boxStyles}
                            imageSrc={item.node.data.foto.fixed.src}
                            btnText={item.node.data.titulo.text}
                            desc={item.node.data.marca.document.data.marca.text.replace(
                              /^\w/,
                              c => c.toUpperCase()
                            )}
                            path={`/productos/${item.node.uid}`}
                          />
                        </Col>
                      ))}
                      <Pagination
                        rootPage="/productos"
                        currentPage={currentPage}
                        numberOfPages={numberOfPages}
                      />
                    </Row>
                  </Container>
                </ListadoWrapper>
              </Col>
            </Row>
          </Container>
        </ListaDeProductosWrapper>
      </main>
    </>
  )
}

export const query = graphql`
  query ProductsListQuery($skip: Int!, $limit: Int!) {
    allPrismicProducto(limit: $limit, skip: $skip) {
      edges {
        node {
          id
          data {
            contenido {
              text
            }
            titulo {
              text
            }
            foto {
              fixed(width: 200, height: 200) {
                ...GatsbyPrismicImageFixed
              }
            }
            marca {
              document {
                ... on PrismicMarca {
                  data {
                    marca {
                      text
                    }
                  }
                }
              }
            }
          }
          uid
        }
      }
    }
  }
`

listaDeProductos.propTypes = {
  sectionStyle: PropTypes.object,
  linkStyle: PropTypes.object,
  boxStyles: PropTypes.object,
}

listaDeProductos.defaultProps = {
  sectionStyle: {
    pt: "89px",
    pb: "100px",
    responsive: {
      medium: {
        pt: "72px",
        pb: "80px",
      },
      small: {
        pt: "53px",
        pb: "60px",
      },
    },
  },
  boxStyles: {
    headingStyle: {
      color: "#000",
    },
  },
  linkStyle: {
    layout: "underline",
    fontSize: "18px",
    fontWeight: 500,
    lineheight: 1.4,
    color: "primary",
    hover: {
      layout: 2,
    },
  },
}
export default listaDeProductos
