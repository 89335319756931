import styled from "styled-components"
import { device } from "../theme"

export const ListadoWrapper = styled.div`
  flex: 1 0 auto;
  .box-item {
    margin-bottom: 30px;
  }
`

export const ListaDeProductosWrapper = styled.div`
  padding-top: 89px;
  padding-bottom: 100px;
  @media ${device.medium} {
    padding-top: 72px;
    padding-bottom: 80px;
  }
  @media ${device.small} {
    padding-top: 53px;
    padding-bottom: 60px;
  }
`
